(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/video-player/assets/javascripts/content-loader.js') >= 0) return;  svs.modules.push('/components/components/video-player/assets/javascripts/content-loader.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : {};
const {
  getJsonLDString
} = isServer ? trinidad.components.require('utils', 'get-json-ld') : svs.utils;
let solidTangoUrl;
function loadVideo(options, callback) {
  if (!options || options.type !== 'solidtango') {
    return callback('Unsupported media source type');
  }
  const {
    videoId,
    title = 'video',
    height = 720,
    width = 1280,
    autoplay = false,
    api = false,
    publishedAt,
    thumbnail
  } = options;
  const src = "https://".concat(solidTangoUrl, "/widgets/embed/").concat(videoId, "?autoFillScreen=0").concat(api ? '&api=true' : '').concat(autoplay ? '&auto_play=true' : '');
  const heightPercent = height / width * 100;
  const model = {
    type: 'solidtango',
    src,
    heightPercent,
    videoId,
    title,
    height,
    width
  };
  const jsonLDTags = getJsonLDString({
    name: title,
    uploadDate: publishedAt,
    thumbnailUrl: thumbnail
  });
  callback(null, _objectSpread(_objectSpread({}, model), {}, {
    jsonLDTags
  }));
}
if (isServer) {
  const urlProvider = require('./utils.es6');
  solidTangoUrl = urlProvider.solidTangoUrl;
  module.exports = {
    loadVideo
  };
} else {
  solidTangoUrl = svs.components.video_player.data.solidTangoUrl || 'svspeltur.solidtango.com';
  svs.components.video_player.contentLoader = {
    loadVideo
  };
}

 })(window);