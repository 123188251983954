(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/video-player/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/components/video-player/assets/javascripts/prescript.js');

'use strict';

var svs = svs || {};

svs.components = svs.components || {};
svs.components.video_player = svs.components.video_player || {};
svs.components.video_player.data = svs.components.video_player.data || {};


 })(window);